export const siteInputStyle = {
  border: "none",
  borderBottom: "2px solid",
  borderColor: "siteFormInput",
  focusBorderColor: "none",
  color: "text",
  borderRadius: "0",
  _focus: {
    boxShadow: "none",
    borderBottom: "2px solid",
    borderColor: "siteFormInput",
  },
  _hover: {
    borderBottom: "2px solid",
    borderColor: "siteFormInput",
  },
};

export const siteButtonStyle = {
  width: "100px",
  height: "40px",
  _hover: {
    color: "gray.800",
    bg: "siteFormButton",
  },
};

export const siteLogInputStyle = {
  size: "sm",
  border: "none",
  focusBorderColor: "yottaBlack",
  borderRadius: "none",
  bg: "yottaBlack",
};

export const timeSwitcherButton = {
  paddingX: "4",
  paddingY: "3",
  borderRadius: "md",
};
