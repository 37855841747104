import { defineStyle } from "@chakra-ui/react";

export default defineStyle({
  // unset styles inherit from button
  height: "unset",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: 0,

  // override with new styles
  color: "yottaGreyDisabled",
  textAlign: "left",
  fontWeight: "inherit",
  opacity: 0.7,

  _dark: {
    color: "yottaGrayFont",

    _active: {
      color: "yottaWhite",
    },

    _hover: {
      color: "yottaWhite",
    },

    _focus: {
      color: "yottaWhite",
    },
  },
});
