import { defineStyle } from "@chakra-ui/react";

export default defineStyle({
  background: "yottaWhite",
  color: "yottaBlack",

  _active: {
    color: "yottaGreen",
  },

  _dark: {
    background: "yottaGreyNatural",
    color: "yottaWhite",
    _active: {
      color: "yottaGreen",
    },
  },
});
