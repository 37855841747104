import { defineStyle } from "@chakra-ui/react";

export default defineStyle({
  background: "yottaGrey",
  color: "yottaWhite",
  border: "1px solid",

  _active: {
    background: "yottaGreen",
    borderColor: "yottaGreen",
  },

  _dark: {
    background: "yottaBlack",
    borderColor: "yottaGrey",

    _active: {
      background: "yottaGreen",
      borderColor: "yottaGreen",
    },
  },
});
