import { defineStyle } from "@chakra-ui/styled-system";

export default defineStyle({
  th: {
    borderColor: "subtle-bg",
  },
  td: {
    borderColor: "subtle-bg",
  },
});
