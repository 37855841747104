import { defineStyle } from "@chakra-ui/styled-system";

export default defineStyle({
  th: {
    borderColor: "subtle-bg",
    padding: "12px 0",
  },
  td: {
    borderColor: "subtle-bg",
    padding: "3px 0",
  },
});
