import { defineStyle } from "@chakra-ui/react";

export default defineStyle({
  background: "yottaWhite",
  color: "yottaBlack",
  border: "1px solid",
  borderColor: "inherit",

  _dark: {
    background: "yottaBlack",
    color: "yottaWhite",
    borderColor: "yottaWhite",
  },
});
