import { defineStyle } from "@chakra-ui/react";

export default defineStyle({
  border: "1px solid transparent",
  background: "yottaGrey",
  color: "yottaWhite",

  _active: {
    background: "yottaGreen",
    borderColor: "yottaGreen",
  },

  _dark: {
    background: "yottaBlack",
    color: "yottaGrayFont",

    _active: {
      borderColor: "yottaGreen",
    },
  },
});
