import {
  StyleFunctionProps,
  ThemeTypings,
  defineStyleConfig,
  extendTheme,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { colors } from "./_colors";
import { semanticTokens } from "./_semanticTokens";
import assignDevicesButton from "./buttons/assignDevicesButton";
import formButton from "./buttons/formButton";
import primary from "./buttons/primary";
import primaryDisabled from "./buttons/primaryDisabled";
import primaryGhost from "./buttons/primaryGhost";
import primaryLink from "./buttons/primaryLink";
import primaryOutlined from "./buttons/primaryOutlined";
import providerButton from "./buttons/providerButton";
import timeSwitcherActive from "./buttons/timeSwitcherActive";
import timeSwitcherInactive from "./buttons/timeSwitcherInactive";
import visualizationControls from "./buttons/visualizationControls";
import visualizationControlsZoom from "./buttons/visualizationControlsZoom";
import small from "./tables/small-table";
import unstyled from "./tables/unstyled-table";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: true,
};

const styles = {
  global: (props: StyleFunctionProps) => ({
    body: {
      bg: "bg",
    },
  }),
};

type Color = ThemeTypings["colors"];

export default extendTheme({
  config,
  colors,
  styles,
  semanticTokens,
  components: {
    Button: defineStyleConfig({
      variants: {
        primary,
        primaryGhost,
        primaryLink,
        primaryOutlined,
        visualizationControls,
        visualizationControlsZoom,
        providerButton,
        formButton,
        assignDevicesButton,
        primaryDisabled,
        timeSwitcherActive,
        timeSwitcherInactive,
      },
    }),
    Table: {
      variants: {
        unstyled,
        small,
      },
    },
    Text: {
      baseStyle: (props: StyleFunctionProps) => ({
        color: mode(colors.tintedBlack, colors.tintedWhite)(props),
      }),
    },
  },
});
