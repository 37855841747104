"use client";
import type { UserRole } from "@yotta-vision/core/models/user";
import type { GetSessionResult } from "@yotta-vision/functions/rest/session";
import * as Equivalence from "effect/Equivalence";
import { constFalse } from "effect/Function";
import * as Match from "effect/Match";
import * as React from "react";

interface SessionContext {
  session: GetSessionResult;
}

interface Props extends React.PropsWithChildren {
  session: GetSessionResult;
}

const SessionContext = React.createContext<SessionContext>({
  session: { type: "public", properties: {} },
});

export const SessionProvider: React.FC<Props> = ({ session, children }) => {
  return (
    <SessionContext.Provider value={{ session }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => React.useContext(SessionContext);

/**
 * Roles form a poset, or a set with a partial order equivalence relation.
 * There are n choose 2 interesting combinations, and the rest are trivial.
 */
const roleEquivalence = Equivalence.make<UserRole>((self, that) => {
  if (self === "user") {
    if (that === "org-admin" || that === "admin") {
      return false;
    }
  } else if (self === "org-admin") {
    if (that === "admin") {
      return false;
    }
  }
  return true;
});

const isUserRole = (role: UserRole) =>
  Match.type<GetSessionResult>().pipe(
    Match.when({ type: "user" }, (session) =>
      roleEquivalence(session.properties.role, role),
    ),
    Match.orElse(constFalse),
  );

export const isOrgAdmin = isUserRole("org-admin");

export const isAdmin = isUserRole("admin");
