import { defineStyle } from "@chakra-ui/react";

export default defineStyle({
  border: "1px solid transparent",
  background: "yottaGrey",
  color: "yottaWhite",

  _dark: {
    background: "yottaGreyDisabled",
    color: "gray.500",
  },
});
