import { defineStyle } from "@chakra-ui/react";

export default defineStyle({
  background: "transparent",
  color: "yottaGrey",

  _active: {
    color: "yottaGreen",
  },

  _dark: {
    color: "yottaWhite",

    _active: {
      color: "yottaGreen",
    },
  },
});
